import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

export default function Documents() {
  const data = useStaticQuery(graphql`
    query {
      wpPage(databaseId: { eq: 6483 }) {
        dokument {
          filerDokument {
            titelFiler
            filFiler {
              mediaItemUrl
            }
          }
        }
      }
    }
  `)
  const documents = data.wpPage.dokument.filerDokument
  return (
    <div>
      {documents.map((document, i) => {
        return <Document key={i} document={document} />
      })}
    </div>
  )
}
function Document({ document }) {
  const { titelFiler, filFiler } = document
  return (
    <div className="flex flex-row w-full justify-between odd:bg-primary/10 even:bg-primary/5 ">
      <div className="p-4">
        <Link to={filFiler}>{titelFiler}</Link>
      </div>
      <div className="border-l border-fourth p-4">
        {" "}
        <Link
          to={filFiler.mediaItemUrl}
          className="underline hover:no-underline "
        >
          Ladda ner
        </Link>
      </div>
    </div>
  )
}
